import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 发送通知
 * @param params
 */
export function pushNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/tenant/notify/pushNotify`,
        method: 'post',
        data: params
    })
}

/**
 * 通知查询
 * @param params
 */
export function getNotifyPage(params) {
  return request({
    url: envInfo.bgApp.notifyPath + '/tenant/notify/page',
    method: 'get',
    params
  })
}

/**
 * 查询通知发送记录
 * @param params
 */
export function getNotifyDetailPage(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/notifyPage',
        method: 'get',
        params
    })
}

/**
 * 新增通知
 * @param params
 */
export function insertNotify(params) {
  return request({
    url: envInfo.bgApp.notifyPath + '/tenant/notify/create',
    method: 'post',
    data: params
  })
}

/**
 * 修改通知
 * @param params
 */
export function updateNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/tenant/notify/update',
        method: 'post',
        data: params
    })
}

/**
 * 删除通知
 * @param params
 */
export function deleteNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/tenant/notify/delete/${params.notifyId}`,
        method: 'delete',
    })
}

/**
 * 查询通知信息
 * @param params
 */
export function findNotifyById(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/tenant/notify/find/${params.notifyId}`,
        method: 'get',
    })
}

/**
 * 查询通知模版内容
 * @param params
 */
export function findNotifyByTemplateId(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/findContent/${params.notifyTemplateId}`,
        method: 'get',
    })
}

/**
 * 查询所有人员
 */
export function findEmployeeList() {
    return request({
        url: envInfo.bgApp.organizationPath + '/tenant/employee/empList',
        method: 'get',
    })
}
